import React, { useState, useCallback } from "react";
import { graphql } from "gatsby";

import { getUniqueCategories } from "../utils/helpers";

import Layout from "../layout";
import Seo from "../components/seo";
import Post from "../models/post";
import PostTabs from "../components/post-tabs";


const HomePage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(({ node }) => new Post(node));
  const categories = ["All", ...getUniqueCategories(posts)];
  const featuredTabIndex = categories.findIndex((category) => category === "featured");
  const [tabIndex, setTabIndex] = useState(featuredTabIndex === -1 ? 0 : featuredTabIndex);
  const onTabIndexChange = useCallback((e, value) => setTabIndex(value), []);

  return (
    <Layout>
      <Seo title="Home" />
      <PostTabs
        posts={posts}
        onChange={onTabIndexChange}
        tabs={categories}
        tabIndex={tabIndex}
      />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 500, truncate: true)
          frontmatter {
            categories
            title
            date(formatString: "YYYY. M. D.")
          }
          fields {
            slug
          }
        }
      }
    }

    site {
      siteMetadata {
        language
        author
        bio {
          name
          description
        }
        social {
          github
          email
        }
      }
    }
  }
`;
